import { Box, Button, Grid, styled, Switch, Typography } from "@mui/material";
import { BoxValue, IconApp, Modal } from "../../";
import { useEffect, useState } from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useUpdateEvse } from "../../../services/evse";
import { HeaderInfoChargepoint } from "./Header";
import SaveIcon from "@mui/icons-material/Save";
import { EAction } from "../../../types/enum";
import { usePermission } from "../../../hooks/CommonContext";

export const ModalReservation = (props: {
  open: boolean;
  onClose: () => void;
  init?: IEvse;
  success: () => void;
}) => {
  const [evse, setEvse] = useState<Partial<IEvse>>({});
  const { evse: evseFromPermission, profile } = usePermission();

  useEffect(() => {
    setEvse({
      reservation_same_day: props.init?.reservation_same_day,
      reservation_grace_period: props.init?.reservation_grace_period,
      reservation_cancel_grace_period:
        props.init?.reservation_cancel_grace_period,
      reservation_block: props.init?.reservation_block,
      reservation_enabled: props.init?.reservation_enabled,
    });
  }, [props.open, props.init?._id]);

  const { mutateAsync } = useUpdateEvse();

  const handleUpdate = async () => {
    try {
      const result = await mutateAsync({
        id: props.init?._id!,
        dto: { ...evse },
      });
      if (result) {
        props.success();
        props.onClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const result = await mutateAsync({
  //         id: props.init?._id!,
  //         dto: { ...evse },
  //       });
  //       if (result) {
  //         props.success();
  //         // props.onClose();
  //       }
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   })();
  // }, [evse]);

  return (
    <Modal
      {...props}
      name="Reservation+"
      description="Customize and control charging station reservations"
      icon={<IconApp width={"30px"} name={"Reservation"} color={"#FFFF00"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={"30px"}>
        <HeaderInfoChargepoint
          model={props.init?.model}
          current_type={props.init?.current_type}
          max_p={props.init?.max_p}
          locationName={props.init?.Location?.name}
          ampeco_evse_id={props.init?.ampeco_evse_id}
        />

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection={"column"}
            gap={3}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box>
                <Typography fontSize={"14px"}>Reservation function</Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Toggle to activate or deactive function)
                </Typography>
              </Box>
              <Switch
                checked={evse?.reservation_enabled}
                onChange={(e) =>
                  setEvse({ ...evse, reservation_enabled: e.target.checked })
                }
              />
            </Box>
            <Box>
              <Box marginBottom={"19px"}>
                <Typography fontSize={"14px"}>Grace Period</Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Time allowance for late-comers)
                </Typography>
              </Box>
              <Box display={"flex"} gap={1} mt={1}>
                {["15", "30"].map((item) => (
                  <BoxValue
                    active={evse?.reservation_grace_period === item}
                    onClick={() =>
                      setEvse({ ...evse, reservation_grace_period: item })
                    }
                  >
                    <Typography fontSize={"20px"} fontWeight="bold">
                      {item}{" "}
                      <span style={{ fontWeight: 500, fontSize: "13px" }}>
                        mins
                      </span>
                    </Typography>
                  </BoxValue>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection={"column"}
            gap={3}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Box>
                <Typography fontSize={"14px"}>Same day reservation</Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Toggle to activate or deactive function)
                </Typography>
              </Box>
              <Switch
                checked={evse?.reservation_same_day}
                onChange={(e) =>
                  setEvse({ ...evse, reservation_same_day: e.target.checked })
                }
              />
            </Box>
            <Box>
              <Box marginBottom={"19px"}>
                <Typography fontSize={"14px"}>
                  Cancellation Grace Period
                </Typography>
                <Typography fontSize={"10px"} color="#9B9B9B">
                  (Allow User To Cancel Booking W/O Penaty)
                </Typography>
              </Box>
              <Box display={"flex"} gap={1} mt={1}>
                {["60", "90"].map((item) => (
                  <BoxValue
                    active={evse?.reservation_cancel_grace_period === item}
                    onClick={() =>
                      setEvse({
                        ...evse,
                        reservation_cancel_grace_period: item,
                      })
                    }
                  >
                    <Typography fontSize={"20px"} fontWeight="bold">
                      {item}{" "}
                      <span style={{ fontWeight: 500, fontSize: "13px" }}>
                        mins
                      </span>
                    </Typography>
                    {/* <Typography fontSize={"20px"}> */}
                    {/*   {item} <span>mins</span> */}
                    {/* </Typography> */}
                  </BoxValue>
                ))}
                {/* <BoxValue */}
                {/*   active={evse?.reservation_block === "90"} */}
                {/*   // onClick={() => setEvse({ ...evse, idle_grace_period: "30" })} */}
                {/* > */}
                {/*   <Typography fontSize={"20px"}> */}
                {/*     90 <span>mins</span> */}
                {/*   </Typography> */}
                {/* </BoxValue> */}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box>
          <Typography fontSize={"14px"}>Reservation period</Typography>
          <Typography fontSize={"10px"} color="#9B9B9B">
            (Time period for each reservation)
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {Array.from({ length: 12 }, (_, i) => (i + 1) * 30).map((item) => (
            <Grid item xs={4} md={3}>
              <BoxValue
                active={evse?.reservation_block === `${item}`}
                onClick={() =>
                  setEvse({ ...evse, reservation_block: `${item}` })
                }
              >
                <Typography
                  fontSize={"20px"}
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="end"
                  fontWeight={"bold"}
                >
                  {item}{" "}
                  <span style={{ fontWeight: "normal", fontSize: "13px" }}>
                    mins
                  </span>
                </Typography>
              </BoxValue>
            </Grid>
          ))}
        </Grid>

        <Box display={"flex"} flexDirection="column" gap={"12px"}>
          <Box>
            <Typography fontSize={"14px"}>Reservation Deposit</Typography>
            <Typography fontSize={"10px"} color="#9B9B9B">
              (Amount pre-auth upon booking)
            </Typography>
          </Box>
          <Box display={"flex"} alignItems="center" gap={2}>
            <Typography fontSize={"15px"}>Current price</Typography>
            <Typography fontSize={"15px"}>
              S${" "}
              <span style={{ color: "#565656" }}>
                {props.init?.reservation_deposit_amount || 0}
              </span>
            </Typography>
          </Box>
          <Box display={"flex"} gap={1} alignItems="center">
            <PriorityHighIcon fontSize="small" />
            <Typography fontSize={"10px"}>
              Reservation deposit amount is prefixed by the platform: novowatt
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2}>
          {evseFromPermission?.includes(EAction.UPDATE) && (
            <Grid item xs={8}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                startIcon={<SaveIcon fontSize="large" />}
                onClick={handleUpdate}
              >
                Save Change
              </Button>
            </Grid>
          )}
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="inherit"
              onClick={props.onClose}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

// const Text = styled(Typography)({ color: "#9B9B9B", fontSize: "13px" });
// const TextName = styled(Typography)({
//   fontSize: "17px",
//   whiteSpace: "nowrap",
//   overflow: "hidden",
//   textOverflow: "ellipsis",
// });
