import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  styled,
  // Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { ColumnTitle, IconApp, Modal, Pagination } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import BoltIcon from "@mui/icons-material/Bolt";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useInfiniteQuery } from "react-query";
import { useMemo, useState } from "react";
import { UserService } from "../../../services/user/user.service";
import {
  useAddRestrictedUserEvse,
  useListUserEvse,
} from "../../../services/evse";
import { useDebounce } from "../../../hooks/useDebounce";
import { HeaderInfoChargepoint } from "./Header";

export const ModalRestrictedUser = (props: {
  open: boolean;
  onClose: () => void;
  init?: IEvse;
}) => {
  const evse = props.init;
  const [users, setUsers] = useState<string[]>([]);
  const { value, setValue } = useDebounce({ initialValue: "", delay: 300 });
  const [page, setPage] = useState(1);
  const { mutateAsync } = useAddRestrictedUserEvse();
  const { data: listUserEvse, refetch } = useListUserEvse({
    page,
    per_page: 5,
    evse_id: evse?._id,
  });

  const { data, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["infinity_user", value],
    enabled: props.open && value?.trim() !== "" && value.length > 7,
    queryFn: ({ pageParam = 1 }) => fetchListUser({ pageParam, phone: value }),
    getNextPageParam: (currentPage, allPage) => {
      const check =
        (currentPage?.per_page || 0) * (currentPage?.page || 0) <
        (currentPage?.total || 0);
      return check ? allPage.length + 1 : undefined;
    },
  });

  const listUser: Array<{ name: string; _id: string; phone: number }> =
    useMemo(() => {
      const arr = data?.pages.map((page) => {
        return (
          page?.data.map((item: any) => ({
            _id: item._id,
            name: item.name || "Unknown",
            phone: item.phone || "Unknown",
          })) || []
        );
      });
      return arr?.flat() || [];
    }, [data?.pages]);

  const handleUpdate = async () => {
    if (users.length !== 0 && evse)
      try {
        const result = await mutateAsync({
          dto: { user_ids: users, evse_ids: [evse._id!] },
        });
        if (result) {
          refetch();
        }
      } catch (error) {
        console.log("error", error);
      }
  };

  return (
    <Modal
      {...props}
      name="Restricted Users"
      description="Manage users with restricted access due to account issues"
      icon={
        <IconApp width={"35px"} name={"RestrictedUsers"} color={"yellow"} />
      }
    >
      <Box display={"flex"} flexDirection="column" gap={"30px"}>
        <HeaderInfoChargepoint
          model={evse?.model}
          current_type={evse?.current_type}
          max_p={evse?.max_p}
          locationName={evse?.Location?.name}
          ampeco_evse_id={evse?.ampeco_evse_id}
        />

        <Box display={"flex"} flexDirection="column" gap={1}>
          <TableContainer>
            <Scrollbars
              autoHeight
              autoHeightMin="100%"
              autoHeightMax="100%"
              style={{ width: "100%" }}
              autoHide
            >
              <Table sx={{ minWidth: 750, background: "none" }}>
                <TableHead>
                  <TableRow>
                    <ColumnTitle align="center" sx={{ minWidth: 70 }}>
                      S/N
                    </ColumnTitle>
                    <ColumnTitle>User</ColumnTitle>
                    <ColumnTitle>Registered mobile no</ColumnTitle>
                    {/* <ColumnTitle>Email</ColumnTitle> */}
                    <ColumnTitle>Status</ColumnTitle>
                    <ColumnTitle>Joined date</ColumnTitle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listUserEvse?.data.map((item: any, index: number) => (
                    <TableRow>
                      <TableCell align="center">
                        {page * 5 - 5 + index + 1}
                      </TableCell>
                      <TableCell>{item.user_name}</TableCell>
                      <TableCell>{item.user_phone}</TableCell>
                      {/* <TableCell>{item.user_email}</TableCell> */}
                      <TableCell>
                        <Chip
                          color="error"
                          label="Disable"
                          style={{ width: "100%" }}
                        />
                      </TableCell>
                      <TableCell>{item.joined_date} </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbars>
          </TableContainer>
          <Pagination
            page={page}
            setPage={setPage}
            totalItems={listUserEvse?.total || 0}
            totalPage={Math.ceil((listUserEvse?.total || 0) / 5)}
            currentPage={page}
          />
        </Box>

        <Box display={"flex"} flexDirection="column" gap="15px">
          <Box display={"flex"} gap={1} alignItems="center">
            <BoltIcon fontSize="medium" />
            <Typography fontSize={"14px"}>
              Add restricted users{" "}
              <span style={{ color: "#9B9B9B" }}>
                Enter the user’s mobile numberin each line to add him/ her as a
                “RU”
              </span>
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection="column" gap={"16px"}>
            <Typography>Your mobile number</Typography>
            <Autocomplete
              onChange={(_, value) => {
                setUsers(value.map((item) => item._id));
              }}
              multiple
              limitTags={5}
              disableCloseOnSelect
              options={listUser}
              getOptionLabel={(option) => `${option.name} - ${option.phone}`}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              ListboxProps={{
                onScroll: (event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    hasNextPage && fetchNextPage();
                  }
                },
              }}
              onInputChange={(_, e) => setValue(e)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Users" />
              )}
              fullWidth
            />
          </Box>
          {/* <Box display={"flex"} gap={1} alignItems="center"> */}
          {/*   <PersonAddAltIcon fontSize="medium" /> */}
          {/*   <Typography fontSize={"14px"}> */}
          {/*     Add the user to ALL charger in the location */}
          {/*   </Typography> */}
          {/*   <Switch defaultChecked /> */}
          {/* </Box> */}

          <Box display={"flex"} gap={1} alignItems="center">
            <PriorityHighIcon fontSize="small" />
            <Typography fontSize={"10px"}>
              To make changes to electricity tariff, please contact your
              relationship offcier
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
              onClick={handleUpdate}
              disabled={!evse || users.length === 0}
            >
              Add more user
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="inherit"
              onClick={props.onClose}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });
const TextName = styled(Typography)({
  fontSize: "17px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const fetchListUser = async ({
  pageParam,
  phone,
}: {
  pageParam: number;
  phone?: string;
}) => {
  try {
    const { data } = await UserService.getListUser({
      page: pageParam,
      per_page: 20,
      phone,
    });
    return data;
  } catch (error) {
    console.log("error", error);
  }
};
