export default ({ color }: { color?: string }) => {
  return (
    <svg
      // width="40"
      // height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5615 14.676C11.5426 14.6919 11.5241 14.7083 11.5062 14.7254C11.2734 14.9459 11.1426 15.245 11.1426 15.5569C11.1426 15.8688 11.2734 16.168 11.5062 16.3885C11.739 16.6091 12.0547 16.733 12.3839 16.733H13.6564L11.5615 14.676ZM17.8021 20.8035L17.7434 21.1182C17.7437 21.121 17.7434 21.1238 17.7425 21.1265C17.7415 21.1291 17.7399 21.1315 17.7378 21.1335L16.1471 29.7059C16.0894 30.017 16.1641 30.3372 16.355 30.5963C16.5458 30.8554 16.8372 31.0323 17.1654 31.0883C17.3277 31.1155 17.494 31.1119 17.6548 31.0777C17.8156 31.0435 17.9676 30.9793 18.102 30.889C18.2364 30.7987 18.3505 30.684 18.4377 30.5515C18.5249 30.4191 18.5835 30.2715 18.61 30.1175L19.7093 24.1589C19.7093 24.1589 19.8647 23.5354 20.1574 23.1161L17.8021 20.8035ZM21.8124 24.7411L22.8044 30.1227C22.831 30.2781 22.89 30.427 22.9779 30.5606C23.0658 30.6942 23.1809 30.8099 23.3166 30.9009C23.4522 30.992 23.6055 31.0565 23.7678 31.0908C23.93 31.1251 24.0978 31.1285 24.2614 31.1008C24.425 31.073 24.5811 31.0147 24.7207 30.9292C24.8602 30.8437 24.9804 30.7328 25.0743 30.6028C25.1681 30.4728 25.2337 30.3265 25.2673 30.1723C25.3008 30.0181 25.3016 29.8591 25.2696 29.7045L24.9135 27.786L21.8124 24.7411ZM23.3216 18.7946C23.2871 18.2447 23.3091 17.7253 23.4481 17.385C23.6577 16.8675 24.0416 16.7317 24.3345 16.7317H29.0272C29.3565 16.7317 29.6722 16.6078 29.905 16.3872C30.1378 16.1667 30.2686 15.8675 30.2686 15.5556C30.2686 15.2437 30.1378 14.9446 29.905 14.724C29.6722 14.5035 29.3565 14.3796 29.0272 14.3796V14.3809H18.8264L23.3216 18.7946ZM23.2802 11.2448C23.2802 12.5919 22.1275 13.684 20.7056 13.684C19.2836 13.684 18.1309 12.5919 18.1309 11.2448C18.1309 9.89772 19.2836 8.80566 20.7056 8.80566C22.1275 8.80566 23.2802 9.89772 23.2802 11.2448Z"
        fill={color ?? "white"}
      />
      <rect
        x="9.80566"
        y="11.0525"
        width="2.71412"
        height="28.7043"
        transform="rotate(-45.5239 9.80566 11.0525)"
        fill={color ?? "white"}
      />
      <path
        d="M36.5416 19.4999C36.5416 28.3595 29.3595 35.5416 20.4999 35.5416C11.6404 35.5416 4.45825 28.3595 4.45825 19.4999C4.45825 10.6404 11.6404 3.45825 20.4999 3.45825C29.3595 3.45825 36.5416 10.6404 36.5416 19.4999ZM7.03414 19.4999C7.03414 26.9369 13.063 32.9657 20.4999 32.9657C27.9369 32.9657 33.9657 26.9369 33.9657 19.4999C33.9657 12.063 27.9369 6.03414 20.4999 6.03414C13.063 6.03414 7.03414 12.063 7.03414 19.4999Z"
        fill={color ?? "white"}
      />
    </svg>
  );
};
