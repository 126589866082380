export default ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="19"
      // height="17"
      viewBox="0 0 19 17"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.46867 4.18766C3.46867 1.93825 5.29218 0.114746 7.54158 0.114746C9.79099 0.114746 11.6145 1.93825 11.6145 4.18766C11.6145 6.43707 9.79099 8.26058 7.54158 8.26058C5.29218 8.26058 3.46867 6.43707 3.46867 4.18766ZM7.54158 1.55225C6.08608 1.55225 4.90617 2.73216 4.90617 4.18766C4.90617 5.64316 6.08608 6.82308 7.54158 6.82308C8.99709 6.82308 10.177 5.64316 10.177 4.18766C10.177 2.73216 8.99709 1.55225 7.54158 1.55225Z"
        fill={color ?? "white"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.114502 13.2918C0.114502 11.3071 1.72348 9.69808 3.70825 9.69808H4.03492C4.21172 9.69808 4.38742 9.72604 4.55549 9.78092L5.38494 10.0518C6.78629 10.5093 8.29687 10.5093 9.69823 10.0518L10.5277 9.78092C10.6958 9.72604 10.8714 9.69808 11.0483 9.69808H11.3749C13.3597 9.69808 14.9687 11.3071 14.9687 13.2918V14.4306C14.9687 15.1524 14.4455 15.7679 13.7331 15.8842C9.63257 16.5537 5.4506 16.5537 1.35005 15.8842C0.63764 15.7679 0.114502 15.1524 0.114502 14.4306V13.2918ZM3.70825 11.1356C2.51739 11.1356 1.552 12.101 1.552 13.2918V14.4306C1.552 14.4479 1.56457 14.4627 1.58168 14.4655C5.52882 15.1099 9.55435 15.1099 13.5015 14.4655C13.5186 14.4627 13.5312 14.4479 13.5312 14.4306V13.2918C13.5312 12.101 12.5658 11.1356 11.3749 11.1356H11.0483C11.023 11.1356 10.9979 11.1396 10.9739 11.1474L10.1444 11.4183C8.45314 11.9705 6.63003 11.9705 4.93874 11.4183L4.10929 11.1474C4.08527 11.1396 4.06018 11.1356 4.03492 11.1356H3.70825Z"
        fill={color ?? "white"}
      />
      <path
        d="M15.6874 2.98975C16.0844 2.98975 16.4062 3.31154 16.4062 3.7085V5.38558H18.0833C18.4802 5.38558 18.802 5.70738 18.802 6.10433C18.802 6.50128 18.4802 6.82308 18.0833 6.82308H16.4062V8.50016C16.4062 8.89712 16.0844 9.21891 15.6874 9.21891C15.2905 9.21891 14.9687 8.89712 14.9687 8.50016V6.82308H13.2916C12.8946 6.82308 12.5728 6.50128 12.5728 6.10433C12.5728 5.70737 12.8946 5.38558 13.2916 5.38558H14.9687V3.7085C14.9687 3.31154 15.2905 2.98975 15.6874 2.98975Z"
        fill={color ?? "white"}
      />
    </svg>
  );
};
