export default ({ color }: { color?: string }) => {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5827 7.40137C15.9786 7.40137 15.4889 6.91168 15.4889 6.30762C15.4889 5.70356 15.9786 5.21387 16.5827 5.21387H21.7387C22.3427 5.21387 22.8324 5.70356 22.8324 6.30762C22.8324 6.91168 22.3427 7.40137 21.7387 7.40137H16.5827Z" fill="#FB9347"/>
      <path d="M11.5227 8.06039C11.9499 8.48753 11.9499 9.18005 11.5227 9.60719L7.87691 13.253C7.44978 13.6802 6.75725 13.6802 6.33012 13.253C5.90298 12.8259 5.90298 12.1334 6.33012 11.7062L9.97595 8.06039C10.4031 7.63326 11.0956 7.63326 11.5227 8.06039Z" fill="#FB9347"/>
      <path d="M19.4993 11.3859C13.6601 11.3859 8.92643 16.1195 8.92643 21.9588C8.92643 27.7981 13.6601 32.5317 19.4993 32.5317C25.3386 32.5317 30.0723 27.7981 30.0723 21.9588C30.0723 21.3547 30.562 20.865 31.166 20.865C31.7701 20.865 32.2598 21.3547 32.2598 21.9588C32.2598 29.0062 26.5467 34.7192 19.4993 34.7192C12.452 34.7192 6.73893 29.0062 6.73893 21.9588C6.73893 14.9114 12.452 9.19838 19.4993 9.19838C20.1034 9.19838 20.5931 9.68806 20.5931 10.2921C20.5931 10.8962 20.1034 11.3859 19.4993 11.3859Z" fill="#FB9347"/>
      <path d="M27.0655 15.1983C27.4041 14.7629 27.3656 14.1435 26.9755 13.7534C26.5855 13.3634 25.966 13.3248 25.5306 13.6635L20.9079 17.259L17.887 19.4167C17.2418 19.8776 16.8588 20.6218 16.8588 21.4148C16.8588 22.7708 17.9581 23.8702 19.3142 23.8702C20.1072 23.8702 20.8513 23.4872 21.3123 22.8419L23.47 19.8211L27.0655 15.1983Z" fill="#FB9347"/>
    </svg>
  );
};
