import React from "react";

const ChargerIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6416 6.50552L10.0003 7.31582H11.0336H14.0069L5.38962 15.5382L6.66011 10.4562L6.81543 9.83496H6.17504H3.73325L7.87159 2.6875H13.6633L10.6416 6.50552ZM6.16284 13.2546L5.74375 14.9296L6.99303 13.7377L12.6145 8.37466L13.5178 7.51289H12.2694H9.59349L12.614 3.69479L13.255 2.88457H12.2219H8.27387H7.98563L7.84118 3.134L4.50837 8.88908L4.07371 9.63965H4.94105H7.06733L6.16284 13.2546Z" stroke="#FFFF00"/>
    </svg>
  );
};

export default ChargerIcon;
