import { Grid, styled, Typography, Box } from "@mui/material";
import { IconApp } from "../../Icon";

type IProps = {
  model?: string;
  current_type?: string;
  max_p?: string | number;
  locationName?: string;
  ampeco_evse_id?: string;
};
export const HeaderInfoChargepoint = (props: IProps) => {
  return (
    <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
      <Grid
        item
        xs={3}
        borderRight="1px solid rgba(255, 255, 255, 0.12)"
        pr={"30px"}
      >
        <Text>Model</Text>
        <TextName>{props.model}</TextName>
      </Grid>
      <Grid
        item
        xs={3}
        borderRight="1px solid rgba(255, 255, 255, 0.12)"
        paddingX={"30px"}
      >
        <Text>Type</Text>
        <Box display={"flex"} gap={2} alignItems="center">
          <IconApp
            name={props.current_type === "ac" ? "AcCharger" : "DcCharger"}
            width="25px"
          />
          <TextName>{props.max_p} kW</TextName>
        </Box>
      </Grid>
      <Grid
        item
        xs={3}
        borderRight="1px solid rgba(255, 255, 255, 0.12)"
        paddingX={"30px"}
      >
        <Text>Location ID</Text>
        <TextName>{props.locationName}</TextName>
      </Grid>
      <Grid item xs={3} paddingX={"30px"}>
        <Text>Charge point ID</Text>
        <TextName>{props.ampeco_evse_id}</TextName>
      </Grid>
    </Grid>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B", fontSize: "13px" });
const TextName = styled(Typography)({
  fontSize: "17px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
