export default ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="35"
      // height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M29.6875 9.14756H25V7.52351C25 7.39591 24.8945 7.2915 24.7656 7.2915H23.125C22.9961 7.2915 22.8906 7.39591 22.8906 7.52351V9.14756H18.5547V7.52351C18.5547 7.39591 18.4492 7.2915 18.3203 7.2915H16.6797C16.5508 7.2915 16.4453 7.39591 16.4453 7.52351V9.14756H12.1094V7.52351C12.1094 7.39591 12.0039 7.2915 11.875 7.2915H10.2344C10.1055 7.2915 10 7.39591 10 7.52351V9.14756H5.3125C4.79395 9.14756 4.375 9.56228 4.375 10.0756V26.7801C4.375 27.2935 4.79395 27.7082 5.3125 27.7082H29.6875C30.2061 27.7082 30.625 27.2935 30.625 26.7801V10.0756C30.625 9.56228 30.2061 9.14756 29.6875 9.14756ZM28.5156 25.6201H6.48438V11.2356H10V12.8597C10 12.9873 10.1055 13.0917 10.2344 13.0917H11.875C12.0039 13.0917 12.1094 12.9873 12.1094 12.8597V11.2356H16.4453V12.8597C16.4453 12.9873 16.5508 13.0917 16.6797 13.0917H18.3203C18.4492 13.0917 18.5547 12.9873 18.5547 12.8597V11.2356H22.8906V12.8597C22.8906 12.9873 22.9961 13.0917 23.125 13.0917H24.7656C24.8945 13.0917 25 12.9873 25 12.8597V11.2356H28.5156V25.6201ZM14.6875 17.0358H9.29688C9.16797 17.0358 9.0625 17.1402 9.0625 17.2678V18.6599C9.0625 18.7875 9.16797 18.8919 9.29688 18.8919H14.6875C14.8164 18.8919 14.9219 18.7875 14.9219 18.6599V17.2678C14.9219 17.1402 14.8164 17.0358 14.6875 17.0358ZM14.6875 20.98H9.29688C9.16797 20.98 9.0625 21.0844 9.0625 21.212V22.604C9.0625 22.7316 9.16797 22.836 9.29688 22.836H14.6875C14.8164 22.836 14.9219 22.7316 14.9219 22.604V21.212C14.9219 21.0844 14.8164 20.98 14.6875 20.98ZM23.7168 15.8352L20.6816 20.0026L19.1348 17.8827C19.0469 17.7608 18.9063 17.6912 18.7568 17.6912H17.1484C16.958 17.6912 16.8467 17.9059 16.958 18.0596L20.3008 22.6475C20.3439 22.7068 20.4007 22.7551 20.4664 22.7884C20.5321 22.8217 20.6049 22.8391 20.6787 22.8391C20.7525 22.8391 20.8253 22.8217 20.891 22.7884C20.9567 22.7551 21.0135 22.7068 21.0566 22.6475L25.8906 16.015C26.002 15.8613 25.8906 15.6467 25.7002 15.6467H24.0918C23.9453 15.6438 23.8047 15.7163 23.7168 15.8352Z"
        fill={color ?? "#FB9347"}
      />
    </svg>
  );
};
