import React from "react";

const SubscriptionPlan = ({ color }: { color?: string }) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2339 4.60247L13.9839 2.35247C13.9317 2.30017 13.8696 2.25868 13.8013 2.23038C13.7331 2.20207 13.6599 2.1875 13.5859 2.1875C13.512 2.1875 13.4388 2.20207 13.3705 2.23038C13.3022 2.25868 13.2402 2.30017 13.188 2.35247L6.43797 9.10247C6.38575 9.15475 6.34434 9.2168 6.31611 9.28509C6.28788 9.35337 6.27338 9.42655 6.27344 9.50044V11.7504C6.27344 11.8996 6.3327 12.0427 6.43819 12.1482C6.54368 12.2537 6.68675 12.3129 6.83594 12.3129H9.08594C9.15983 12.313 9.23301 12.2985 9.30129 12.2703C9.36958 12.242 9.43163 12.2006 9.48391 12.1484L16.2339 5.39841C16.2862 5.34617 16.3277 5.28413 16.356 5.21585C16.3843 5.14756 16.3989 5.07436 16.3989 5.00044C16.3989 4.92652 16.3843 4.85333 16.356 4.78504C16.3277 4.71675 16.2862 4.65471 16.2339 4.60247ZM8.8532 11.1879H7.39844V9.73318L11.8984 5.23318L13.3532 6.68794L8.8532 11.1879ZM14.1484 5.89271L12.6937 4.43794L13.5859 3.54568L15.0407 5.00044L14.1484 5.89271ZM15.8359 9.50044V15.1254C15.8359 15.4238 15.7174 15.71 15.5064 15.9209C15.2955 16.1319 15.0093 16.2504 14.7109 16.2504H3.46094C3.16257 16.2504 2.87642 16.1319 2.66544 15.9209C2.45446 15.71 2.33594 15.4238 2.33594 15.1254V3.87544C2.33594 3.57707 2.45446 3.29093 2.66544 3.07995C2.87642 2.86897 3.16257 2.75044 3.46094 2.75044H9.08594C9.23512 2.75044 9.3782 2.80971 9.48369 2.91519C9.58917 3.02068 9.64844 3.16376 9.64844 3.31294C9.64844 3.46213 9.58917 3.6052 9.48369 3.71069C9.3782 3.81618 9.23512 3.87544 9.08594 3.87544H3.46094V15.1254H14.7109V9.50044C14.7109 9.35126 14.7702 9.20818 14.8757 9.10269C14.9812 8.99721 15.1243 8.93794 15.2734 8.93794C15.4226 8.93794 15.5657 8.99721 15.6712 9.10269C15.7767 9.20818 15.8359 9.35126 15.8359 9.50044Z" fill="#FFFF00"/>
    </svg>
  );
};

export default SubscriptionPlan;
