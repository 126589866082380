import {
  Box,
  Button,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import SellIcon from "@mui/icons-material/Sell";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { ReactNode, useEffect, useState } from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BoltIcon from "@mui/icons-material/Bolt";
import { useUpdateEvse } from "../../../services/evse";
import { HeaderInfoChargepoint } from "./Header";
import { EAction } from "../../../types/enum";
import { usePermission } from "../../../hooks/CommonContext";

export const ModalPriceAdjustment = (props: {
  open: boolean;
  onClose: () => void;
  init?: IEvse;
  success: () => void;
}) => {
  const { mutateAsync } = useUpdateEvse();

  const [valueUpdate, setValueUpdate] = useState<{
    price_normal: number;
    price_vip: number;
  }>({ price_vip: 0, price_normal: 0 });

  useEffect(() => {
    setValueUpdate({
      price_normal: props.init?.price_normal || 0,
      price_vip: props.init?.price_vip || 0,
    });

    return () => {
      setValueUpdate({
        price_normal: 0,
        price_vip: 0,
      });
    };
  }, []);

  const { evse, profile } = usePermission();

  const handleUpdate = async () => {
    try {
      const result = await mutateAsync({
        id: props.init?._id!,
        dto: { /* ...props.init, */ ...valueUpdate },
      });
      if (result) {
        props.success();
        props.onClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Modal
      {...props}
      name="Price adjustment"
      description="Adjust pricing strategies to optimize revenue"
      icon={<IconApp width={"30px"} name={"Cash"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <HeaderInfoChargepoint
          model={props.init?.model}
          current_type={props.init?.current_type}
          max_p={props.init?.max_p}
          locationName={props.init?.Location?.name}
          ampeco_evse_id={props.init?.ampeco_evse_id}
        />

        <Grid container spacing={2}>
          <BoxPrice
            icon={<IconApp name="IconPublicPricing" width={20} />}
            name="Public Pricing"
            des="Applies To Public Users"
            value={valueUpdate.price_normal}
            callback={(e) => {
              setValueUpdate({ ...valueUpdate, price_normal: e });
            }}
            currentPrice={props.init?.price_normal || 0}
          />
          <BoxPrice
            icon={<IconApp name="RestrictedUsers" width={20} color="white" />}
            name="Restricted Pricing"
            des="(Applies to Restricted Users)"
            value={valueUpdate.price_vip}
            callback={(e) => {
              setValueUpdate({ ...valueUpdate, price_vip: e });
            }}
            currentPrice={props.init?.price_vip || 0}
          />
        </Grid>

        <Box display={"flex"} gap={0.5} alignItems="center">
          <BoltIcon fontSize="medium" />
          <Typography fontSize={"14px"}>
            Electricity Tariff Rate
            <span>(Cost Of Electricity)</span>
          </Typography>
        </Box>

        <Box display={"flex"} alignItems="center" gap={2}>
          <Typography fontSize={"15px"}>Current Price</Typography>
          <Typography
            fontSize={"15px"}
            display="flex"
            alignItems={"center"}
            gap={"3px"}
          >
            S${" "}
            <span
              style={{
                color: "#565656",
                fontSize: "18px",
              }}
            >
              {props.init?.tariff || 0}
            </span>
          </Typography>
        </Box>

        <Box display={"flex"} gap={1} alignItems="center">
          <PriorityHighIcon fontSize="small" />
          <Typography fontSize={"10px"}>
            To make changes to electricity tariff, please contact your
            relationship offcier
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {evse?.includes(EAction.UPDATE) && (
            <Grid item xs={8}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                startIcon={<SaveIcon fontSize="large" />}
                onClick={handleUpdate}
              >
                Save change
              </Button>
            </Grid>
          )}
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={props.onClose}
              color="inherit"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });
const TextName = styled(Typography)({
  fontSize: "17px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const BoxPrice = (props: {
  name: string;
  icon: ReactNode;
  des: string;
  currentPrice: number;
  value: number;
  callback: (e: number) => void;
}) => {
  return (
    <Grid item xs={12} md={6} display="flex" flexDirection={"column"} gap={1}>
      <Box display={"flex"} gap={1} alignItems="center">
        {props.icon}
        <Typography fontSize={"14px"}>
          {props.name} <span style={{ fontSize: "10px" }}>{props.des}</span>
        </Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography fontSize={"15px"}>Current Price</Typography>
        <Typography fontSize={"15px"} display={"flex"} gap={"3px"}>
          S$ <p style={{ color: "#9B9B9B" }}>{props.currentPrice}</p>
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={"15px"} mb={1}>
          New
        </Typography>
        <TextField
          fullWidth
          // size="small"
          autoComplete="off"
          placeholder="Input Your Values"
          InputProps={{
            endAdornment: <InputAdornment position="end">S$</InputAdornment>,
          }}
          value={props.value}
          type="number"
          onChange={(e) => props.callback(+e.target.value)}
        />
      </Box>
    </Grid>
  );
};
