import { Box, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { tokens } from "../../theme";
import { IconApp, IconType } from "../Icon";

interface IHeaderPage {
  icon: IconType;
  iconColor?: string;
  title: string;
  desc: string;
  button?: ReactNode;
}

const HeaderPage = ({ title, desc, button, icon, iconColor }: IHeaderPage) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent="space-between"
      width={"100%"}
    >
      <Box display={"flex"} alignItems="center" gap={"15px"}>
        <IconApp name={icon} width={30} color={iconColor} />
        <Box>
          <Typography
            // sx={{
            //   fontSize: {
            //     xs: "13px",
            //     sm: "25px",
            //   },
            // }}
            fontSize={"25px"}
            color={colors.primary.text}
            fontWeight={"700"}
          >
            {title}
          </Typography>
          <Typography
            // sx={{
            //   fontSize: {
            //     xs: "6px",
            //     sm: "10px",
            //   },
            // }}
            fontSize={"10px"}
            color={"#9b9b9b"}
            fontWeight={"400"}
          >
            {desc}
          </Typography>
        </Box>
      </Box>
      <Box>{button}</Box>
    </Box>
  );
};

export default HeaderPage;
