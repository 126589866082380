import { HttpClient } from "../../network";

class GetMultiple extends HttpClient {
  getList = async (apiList: string, params: TParams & {name?: string}) => {
    const data = await this.instance.get<IResponse<Array<{}>>>(apiList, {
      params,
    });
    return data;
  };
}
export const GetListMultipleService = new GetMultiple();
