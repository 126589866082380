export default ({ color }: { color?: string }) => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7028 6.90088C17.0987 6.90088 16.609 6.41119 16.609 5.80713C16.609 5.20307 17.0987 4.71338 17.7028 4.71338H22.8588C23.4628 4.71338 23.9525 5.20307 23.9525 5.80713C23.9525 6.41119 23.4628 6.90088 22.8588 6.90088H17.7028Z" fill="#FB9347"/>
      <path d="M12.6429 7.55991C13.07 7.98704 13.07 8.67957 12.6429 9.1067L8.99703 12.7525C8.5699 13.1797 7.87737 13.1797 7.45023 12.7525C7.0231 12.3254 7.0231 11.6329 7.45023 11.2057L11.0961 7.55991C11.5232 7.13277 12.2157 7.13277 12.6429 7.55991Z" fill="#FB9347"/>
      <path d="M20.6195 10.8854C14.7802 10.8854 10.0465 15.619 10.0465 21.4583C10.0465 27.2976 14.7802 32.0312 20.6195 32.0312C26.4587 32.0312 31.1924 27.2976 31.1924 21.4583C31.1924 20.8542 31.6821 20.3646 32.2861 20.3646C32.8902 20.3646 33.3799 20.8542 33.3799 21.4583C33.3799 28.5057 27.6669 34.2187 20.6195 34.2187C13.5721 34.2187 7.85905 28.5057 7.85905 21.4583C7.85905 14.4109 13.5721 8.69789 20.6195 8.69789C21.2235 8.69789 21.7132 9.18758 21.7132 9.79164C21.7132 10.3957 21.2235 10.8854 20.6195 10.8854Z" fill="#FB9347"/>
      <path d="M28.1856 14.6978C28.5243 14.2624 28.4857 13.643 28.0956 13.253C27.7056 12.8629 27.0862 12.8243 26.6507 13.163L22.028 16.7585L19.0071 18.9162C18.3619 19.3771 17.9789 20.1213 17.9789 20.9143C17.9789 22.2704 19.0782 23.3697 20.4343 23.3697C21.2273 23.3697 21.9715 22.9867 22.4324 22.3414L24.5901 19.3206L28.1856 14.6978Z" fill="#FB9347"/>
    </svg>
  );
};
