import { Box, styled, Typography } from "@mui/material";
import { bannerDashboard } from "../assets/images";

export const Banner = (props: {
  title1?: string;
  title2?: string;
  description?: string;
  img?: string;
}) => {
  return (
    <Card
      bg={props?.img}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: "25px",
            md: "30px",
            xl: "39px",
          },
        }}
        fontSize={"39px"}
        fontWeight={"700"}
        color={"white"}
        width="60%"
      >
        {props.title1}
        <br />
        {props.title2}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xs: "11px",
            md: "16px",
          },
        }}
        fontWeight={"400"}
        color={"white"}
        width="50%"
      >
        {props.description}
      </Typography>
    </Card>
  );
};

export const ShortBanner = (props: {
  title1?: string;
  title2?: string;
  description?: string;
  img?: string;
}) => {
  return (
    <Card
      bg={props?.img}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        padding: "22px 21px",
        height: "255px",
        gap: "4px",
      }}
    >
      <Typography
        sx={{
          width: "60%",
          fontSize: "20px",
          // fontSize: {
          // xs: "20px",
          // md: "30px",
          // xl: "39px",
          // },
        }}
        // fontSize={"39px"}
        fontWeight={"700"}
        color={"white"}
      >
        {props.title1}
        <br />
        {props.title2}
      </Typography>
      <Typography
        sx={{
          width: "60%",
          fontSize: {
            xs: "13px",
            // md: "16px",
          },
        }}
        fontWeight={"400"}
        color={"white"}
      >
        {props.description}
      </Typography>
    </Card>
  );
};

const Card = styled(Box)<{ bg?: string; padding?: string }>`
  width: 100%;
  height: 255px;
  padding: ${(props) => (props.padding ? props.padding : "39px 46px")};
  background-image: url(${(props) => (props.bg ? props.bg : bannerDashboard)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  // z-index: 0;
  border-radius: 20px;
  overflow: hidden;

  &:before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;
