import React from "react";

const IdlingIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99854 2C6.86104 2 5.47579 3.51425 3.83704 4.06625C3.17104 4.29125 2.83729 4.403 2.70304 4.56125C2.56804 4.71875 2.52829 4.9505 2.44954 5.4125C1.60429 10.3595 3.45154 14.933 7.85629 16.7135C8.32954 16.9048 8.56654 17 9.00079 17C9.43579 17 9.67279 16.904 10.146 16.7128C14.5508 14.933 16.3958 10.3595 15.5505 5.4125C15.471 4.9505 15.432 4.71875 15.297 4.5605C15.162 4.403 14.829 4.2905 14.163 4.06625C12.5235 3.51425 11.136 2 8.99854 2Z" stroke="#FFFF00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.87475 9.62525C9.666 10.4165 10.7085 11.2205 10.7085 11.2205L12.1373 9.79175C12.1373 9.79175 11.3332 8.74925 10.542 7.958C9.75 7.1675 8.7075 6.36275 8.7075 6.36275L7.2795 7.7915C7.2795 7.7915 8.0835 8.834 8.87475 9.62525ZM8.87475 9.62525L6.375 12.125M12.375 9.55325L10.47 11.4583M8.9475 6.125L7.0425 8.03" stroke="#FFFF00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default IdlingIcon;
